<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-data-table
      :headers="headers"
      :items="procesosImportacion"
      class="elevation-1"
      :loading="loadingProcesos"
      :search="search"
    >
      <template v-slot:[`item.anulado`]="{ item }">
        <v-icon v-if="item.fecAnula !== null" small color="primary">
          {{ checkIcon }}
        </v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col v-if="canCreate" cols="4" align="end">
              <v-btn
                color="primary"
                class="to-right"
                @click="toggleModalNuevoProcesoImportacion()"
              >
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <section style="display: inline" v-on="on">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                :disabled="item.fecAnula !== null"
                @click="seeProceso(item.procId)"
              >
                {{ seeIcon }}
              </v-icon>
            </section>
          </template>
          <span v-if="item.fecAnula !== null">No se puede ver</span>
          <span v-else>Ver proceso</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-if="canAnular" v-slot:activator="{ on, attrs }">
            <section style="display: inline" v-on="on">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                :disabled="!item.canAnular"
                @click="anularProceso(item.procId)"
              >
                {{ anularIcon }}
              </v-icon>
            </section>
          </template>
          <span v-if="item.fecAnula !== null">No se puede anular</span>
          <span v-else>Anular proceso </span>
        </v-tooltip>
      </template></v-data-table
    >

    <v-dialog
      v-model="modalNuevoProcesoImportacion"
      v-if="modalNuevoProcesoImportacion"
      @keydown.esc="toggleModalNuevoProcesoImportacion"
      max-width="60%"
      persistent
    >
      <NuevoProcesoImportacionBonifRecargo
        @toggleModalProcesoImportacionBonifRecargo="
          toggleModalNuevoProcesoImportacion
        "
      ></NuevoProcesoImportacionBonifRecargo>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showAnularModal"
      :isLoading="isLoadingAnular"
      @onDeleteItem="confirmAnularProceso()"
    />
    <v-dialog
      v-model="openModalSeeProcess"
      v-if="openModalSeeProcess"
      @keydown.esc="openModalSeeProcess = false"
      max-width="55%"
      persistent
    >
      <v-card :loading="loadingProcesosToSee">
        <v-container class="p-0 pr-3">
          <v-card-title class="primary--text py-0 pl-1">
            <v-col>Datos del proceso: {{ idToSee }}</v-col>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headersSeeProcess"
              :items="itemsProcessToSee"
              class="elevation-1"
            >
            </v-data-table>
          </v-card-text>
          <v-card-actions class="pr-4">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="itemsProcessToSee.length == 0"
              color="primary"
              outlined
              @click="exportDataToSee"
              class="to-right fontsize"
            >
              Exportar detalle
            </v-btn>
            <v-btn
              outlined
              @click="(openModalSeeProcess = false), (itemsProcessToSee = [])"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import NuevoProcesoImportacionBonifRecargo from "@/components/modules/cuotas/devengamientos/NuevoProcesoImportacionBonifRecargo.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "ProcesosImportacionBonifRecargo",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    NuevoProcesoImportacionBonifRecargo,
  },
  data() {
    return {
      routeToGo: "ConsultaBonifRecargo",
      title: "Procesos de importación bonificaciones y recargos",
      search: "",
      titleDelete: "¿Desea anular el proceso?",
      searchIcon: enums.icons.SEARCH,
      checkIcon: enums.icons.CHECK_OUTLINE,
      anularIcon: enums.icons.CIRCLE_NONE,
      seeIcon: enums.icons.SEE,
      modalProcesoImportacion: false,
      canCreate: false,
      canAnular: false,
      expanded: [],
      optionCode: enums.webSiteOptions.IMPORTACION_BONIFICACIONES_RECARGOS,
      procesosImportacion: [],
      loadingProcesos: false,
      isLoadingAnular: false,
      headers: [
        {
          text: "Número proceso",
          align: "start",
          value: "procId",
          sortable: false,
        },
        {
          text: "Fecha alta",
          align: "start",
          value: "fecAlta",
          sortable: false,
        },
        {
          text: "Usuario alta",
          align: "start",
          value: "usuAlta",
          sortable: false,
        },
        {
          text: "Nombre archivo",
          align: "start",
          value: "rutaArchivo",
          sortable: false,
        },
        {
          text: "Anulado",
          align: "center",
          value: "anulado",
          sortable: false,
        },
        {
          text: "Fecha anulado",
          align: "start",
          value: "fecAnula",
          sortable: false,
        },
        {
          text: "Usuario anulado",
          align: "start",
          value: "usuAnula",
          sortable: false,
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      procesoImportacionId: null,
      modalVerDetalleProcesoImportancion: false,
      showAnularModal: false,
      modalNuevoProcesoImportacion: false,
      idToAnular: null,
      allowedActions: null,
      openModalSeeProcess: false,
      itemsProcessToSee: [],
      loadingProcesosToSee: false,
      idToSee: null,
      headersSeeProcess: [
        {
          text: "N° de grupo familiar",
          sortable: false,
          align: "left",
          value: "nroGrupo",
        },
        {
          text: "Afiliado titular",
          sortable: true,
          value: "afiliadoTitular",
        },
        {
          text: "Tipo",
          sortable: false,
          value: "tipoBonifRecargo",
        },
        {
          text: "Valor",
          sortable: false,
          align: "right",
          value: "porcentaje",
        },
        {
          text: "Fecha vigencia desde",
          sortable: false,
          align: "right",
          value: "vigenciaDesde",
        },
        {
          text: "Fecha vigencia hasta",
          sortable: false,
          align: "right",
          value: "vigenciaHasta",
        },
        {
          text: "Observaciones",
          sortable: false,
          align: "left",
          value: "observaciones",
        },
      ],
    };
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setPermisos();
    this.loadProcesosImportacionBonifRecargo();
  },
  methods: {
    ...mapActions({
      getBonificacionesYRecargosByProceso:
        "devengamientos/getBonificacionesYRecargosByProceso",
      getProcesosImportacionBonificaRecargo:
        "devengamientos/getProcesosImportacionBonificaRecargo",
      anularProcesoImportacionBonificaRecargo:
        "devengamientos/anularProcesoImportacionBonificaRecargo",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVA_IMPORTACION_BONIFICACIONES_RECARGOS:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .ANULAR_IMPORTACION_BONIFICACIONES_RECARGOS:
            this.canAnular = true;
            break;
          default:
            break;
        }
      });
    },
    seeProceso(id) {
      this.openModalSeeProcess = true;
      this.loadingProcessToSee = true;
      this.idToSee = id;
      this.loadSeeProcess();
    },
    async loadSeeProcess() {
      this.loadingProcesosToSee = true;
      const res = await this.getBonificacionesYRecargosByProceso(this.idToSee);
      if (res) this.itemsProcessToSee = res;
      else {
        this.setAlert({
          type: "warning",
          message: "No se encontraron datos del procesos.",
        });
      }
      this.loadingProcesosToSee = false;
    },
    async loadProcesosImportacionBonifRecargo() {
      this.loadingProcesos = true;
      const procesos = await this.getProcesosImportacionBonificaRecargo();
      this.procesosImportacion = procesos;
      this.loadingProcesos = false;
    },
    toggleModalNuevoProcesoImportacion() {
      this.modalNuevoProcesoImportacion = !this.modalNuevoProcesoImportacion;
      this.loadProcesosImportacionBonifRecargo();
    },
    anularProceso(id) {
      this.idToAnular = id;
      this.showAnularModal = true;
    },
    exportDataToSee() {
      let result = [];
      this.itemsProcessToSee.forEach((x) =>
        result.push({
          ["Afiliado"]: x.afiliadoTitular?.toString(),
          ["Tipo documento"]: x.tipoDoc?.toString(),
          ["Numero documento"]: x.nroDoc?.toString(),
          ["Numero grupo"]: x.nroGrupo?.toString(),
          ["Tipo"]: x.tipoBonifRecargo?.toString(),
          ["Porcentaje"]: x.porcentaje?.toString(),
          ["Vigencia desde"]: x.vigenciaDesde?.toString(),
          ["Vigencia hasta"]: x.vigenciaHasta?.toString(),
          ["Observaciones"]: x.observaciones?.toString(),
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(
        formato,
        `Detalle proceso ${this.idToSee.toString()}`
      );
      this.openModalSeeProcess = false;
    },
    async confirmAnularProceso() {
      try {
        this.isLoadingAnular = true;
        const response = await this.anularProcesoImportacionBonificaRecargo(
          this.idToAnular
        );
        if (response.status === 200) {
          if (response.data)
            this.setAlert({ type: "success", message: "Anulado con éxito." });
          else
            this.setAlert({ type: "warning", message: "No se pudo anular." });
        }
      } catch {}
      this.isLoadingAnular = false;
      this.showAnularModal = false;
      this.loadProcesosImportacionBonifRecargo();
    },
  },
};
</script>