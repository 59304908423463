<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        <v-col cols="8">Nueva importación de bonificaciones y recargos</v-col>
      </v-card-title>
      <v-card-text>
        <!-- Proceso de importación -->
        <v-row cols="12" class="py-0">
          <v-col cols="7">
            <v-file-input
              ref="file"
              v-model="file"
              :disabled="loading"
              :loading="loading"
              id="fileUpload"
              type="file"
              dense
              label="Excel"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              @change="importExcel"
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2" class="py-0">
            <v-btn
              color="primary"
              class="no-upper-case to-right"
              outlined
              @click="openModalSeeFormatoArchivo()"
            >
              Ver formato
            </v-btn></v-col
          >
          <v-btn class="mr-4" outlined @click="closeModalExcel()">
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!enableProcess || !file"
            :loading="loadingProcess"
            @click="saveProcesoImportacion(excelDataTranslated)"
          >
            Procesar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-container>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="55%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los registros con error.
            </span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="exportExcelError()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <FormatoArchivoDialog
      :maxWidth="'65%'"
      :formatoTitle="formatoTitle"
      :headers="headers"
      :datosFormatoArchivo="datosFormatoArchivo"
      :dataToExport="dataToExport"
      :openFormatoArchivoDialog.sync="showSeeFormatModal"
    />
  </v-card>
</template>

  <script>
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import rules from "@/utils/helpers/rules";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";

export default {
  name: "NuevoProcesoImportacionBonifRecargo",
  components: { FormatoArchivoDialog },
  data() {
    return {
      rules: rules,
      loading: false,
      entFinId: null,
      comprobantesAGenerar: [],
      excelData: [],
      modalExportarExcel: false,
      registrosErrores: [],
      archivoEnProceso: false,
      file: null,
      registrosError: [],
      fileName: null,
      formatoTitle: "Formato de archivo",
      headers: [],
      datosFormatoArchivo: [],
      enableProcess: false,
      loadingProcess: false,
      showSeeFormatModal: false,
      excelDataTranslated: [],
      dataToExport: {
        formatoName: "Proceso importación bonificaciones y recargo modelo",
        data: [
          {
            ["Tipo documento"]: "DNI",
            ["Numero documento"]: "41585684",
            ["Numero grupo"]: "7895464",
            ["Porcentaje"]: "-1.500",
            ["Vigencia desde"]: "202001",
            ["Vigencia hasta"]: "202512",
            ["Observaciones"]: "",
          },
          {
            ["Tipo documento"]: "LC",
            ["Numero documento"]: "41250360",
            ["Numero grupo"]: "325659",
            ["Porcentaje"]: "1.500",
            ["Vigencia desde"]: "202001",
            ["Vigencia hasta"]: "202512",
            ["Observaciones"]: "",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      saveProcesoImportacionBonifRecargos:
        "devengamientos/saveProcesoImportacionBonifRecargos",
      getFormatoProcesoImportacionBonifRecargo:
        "devengamientos/getFormatoProcesoImportacionBonifRecargo",
    }),
    async importExcel() {
      if (this.file) {
        try {
          this.getArchivo(0);
          const archivoValido = this.verifArchivo();
          if (archivoValido) {
            this.loading = false;
            this.fileName = this.file.name;
            this.excelDataTranslated = [];

            this.excelData[0].filas.forEach((x) => {
              // Normaliza las claves del objeto a minúsculas y sin espacios
              const normalizedData = {};
              for (const key in x) {
                if (x.hasOwnProperty(key)) {
                  const normalizedKey = key.toLowerCase().replace(/\s+/g, "");
                  normalizedData[normalizedKey] = x[key];
                }
              }
              // Columnas esperadas normalizadas
              const expectedColumns = [
                "vigenciadesde",
                "vigenciahasta",
                "porcentaje",
              ];
              // Normaliza las claves del primer objeto para obtener las columnas del archivo
              const firstRow = this.excelData[0].filas[0];
              const actualColumns = Object.keys(firstRow).map((key) =>
                key.toLowerCase().replace(/\s+/g, "")
              );
              const missingColumns = expectedColumns.filter(
                (col) => !actualColumns.includes(col)
              );
              if (missingColumns.length > 0) {
                this.setAlert({
                  type: "warning",
                  message: `El nombre de columnas no es el permitido en la especificación. Verifique`,
                });
                this.$refs["file"].reset();
                this.loading = false;
                this.archivoEnProceso = false;
                return;
              }

              this.excelDataTranslated.push({
                tipoDoc: normalizedData["tipodocumento"],
                nroDoc: normalizedData["numerodocumento"],
                nroGrupo: normalizedData["numerogrupo"],
                vigenciaDesde: normalizedData["vigenciadesde"],
                vigenciaHasta: normalizedData["vigenciahasta"],
                porcentaje: normalizedData["porcentaje"],
                reglaComercial: normalizedData["reglacomercial"],
                observaciones: normalizedData["observaciones"],
              });
            });
            this.enableProcess = true;
          } else setTimeout(this.importExcel, 2000);
        } catch (error) {
          // contempla el error de formato dentro del importExportExcel.js
          this.setAlert({ type: "warning", message: error });
          this.$refs["file"].reset();
          this.loading = false;
          this.archivoEnProceso = false;
        }
      }
    },
    async saveProcesoImportacion(datosProceso) {
      this.loadingProcess = true;
      try {
        const response = await this.saveProcesoImportacionBonifRecargos({
          registros: datosProceso,
          nombreArchivo: this.fileName.trim(),
        });
        if (response.registros.length > 0) {
          this.modalExportarExcel = true;
          this.registrosErrores = response.registros;
        } else {
          this.setAlert({
            type: "success",
            message: "Procesado con éxito, no hubo errores.",
          });
          this.closeModalExcel();
          this.loading = false;
          this.loadingProcess = false;
          this.enableProcess = false;
        }
      } catch {
        this.loading = false;
        this.loadingProcess = false;
        this.enableProcess = false;
      }
    },
    exportExcelError() {
      let result = [];
      this.registrosErrores.forEach((x) =>
        result.push({
          ["Tipo documento"]: x.tipoDoc?.toString(),
          ["Numero documento"]: x.nroDoc?.toString(),
          ["Numero grupo"]: x.nroGrupo?.toString(),
          ["Vigencia desde"]: x.vigenciaDesde?.toString(),
          ["Vigencia hasta"]: x.vigenciaHasta?.toString(),
          ["Porcentaje"]: x.porcentaje?.toString(),
          ["Observaciones"]: x.observaciones?.toString(),
          ["Errores"]: x.errores,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Errores registros");
      this.closeModalExcel();
    },
    getArchivo(rowsToIgnore) {
      // si el archivoEnProceso es false, significa que es la primera vez que llama al metodo
      if (this.archivoEnProceso == false) {
        this.loading = true;
        this.archivoEnProceso = true;
        this.$refs["file"].validate(true);
        if (this.$refs.file.validate())
          this.excelData = helpersExcel.excelImport(this.file, rowsToIgnore);
      }
    },
    verifArchivo() {
      if (this.excelData[0]?.filas != undefined) {
        this.archivoEnProceso = false;
        return true;
      }
      return false;
    },
    async openModalSeeFormatoArchivo() {
      const formatoProceso =
        await this.getFormatoProcesoImportacionBonifRecargo();
      this.datosFormatoArchivo = formatoProceso;
      this.headers = [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo",
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax",
        },
        {
          text: "Formato",
          value: "formato",
          align: "start",
          sortable: false,
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false,
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false,
        },
      ];
      this.showSeeFormatModal = true;
    },
    closeModalExcel() {
      this.$emit("toggleModalProcesoImportacionBonifRecargo");
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>